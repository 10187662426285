<template>
  <div class="gonetwork2">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/gonetworkicon.png" @click="gonetwork0" />
          <div @click="gonetwork0">首页</div>
          <div @click="gonetwork1">产品介绍</div>
          <div class="active" @click="gonetwork2">功能介绍</div>
          <div @click="gonetwork3">下载</div>
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="gonetworkmain"></div>
        <!-- <div class="valback1">
          <div class="valinfo1">
            <img src="../../assets/img/gonetwork2_1.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon1.png" />
              <div class="tal">在线赚钱</div>
              <div class="tel">
                <div></div>
                <div>做任务轻松赚出零花钱</div>
              </div>
              <div class="tel">
                <div></div>
                <div>奖励金额可提现至支付宝、银行卡</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon2.png" />
            </div>
          </div>
        </div> -->
        <div class="valback2">
          <div class="valinfo2">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon3.png" />
              <div class="tal">找工作</div>
              <div class="tel">
                <div></div>
                <div>同城推送海量电竞行业热门职位招聘信息，轻松帮您找工作</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon4.png" />
            </div>
            <img src="../../assets/img/gonetwork2_2.png" />
          </div>
        </div>
        <div class="valback3">
          <div class="valinfo3">
            <img src="../../assets/img/gonetwork2_3.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon5.png" />
              <div class="tal">极速上机</div>
              <div class="tel">
                <div></div>
                <div>
                  与“8圈鹰眼”实时联动，无需刷卡或扫码，一键确认即可快速上机
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="valback4">
          <div class="valinfo4">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon6.png" />
              <div class="tal">扫码上机</div>
              <div class="tel">
                <div></div>
                <div>一键扫码，刷脸上机</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon7.png" />
            </div>
            <img src="../../assets/img/gonetwork2_4.png" />
          </div>
        </div>
        <div class="valback5">
          <div class="valinfo5">
            <img src="../../assets/img/gonetwork2_5.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon8.png" />
              <div class="tal">自主下机</div>
              <div class="tel">
                <div></div>
                <div>轻松愉悦，一键下机</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon9.png" />
            </div>
          </div>
        </div>
        <div class="valback6">
          <div class="valinfo6">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon10.png" />
              <div class="tal">网费充值</div>
              <div class="tel">
                <div></div>
                <div>支持使用微信、支付宝、云闪付支付</div>
              </div>
              <div class="tel">
                <div></div>
                <div>更方便、快捷的充值网费</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon11.png" />
            </div>
            <img src="../../assets/img/gonetwork2_6.png" />
          </div>
        </div>
        <div class="valback7">
          <div class="valinfo7">
            <img src="../../assets/img/gonetwork2_7.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon12.png" />
              <div class="tal">座位预订</div>
              <div class="tel">
                <div></div>
                <div>提前预订心仪座位，开黑首选</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon13.png" />
              <div class="tel2">已选座位：</div>
              <img class="img3" src="../../assets/img/gonetwork2_icon14.png" />
            </div>
          </div>
        </div>
        <div class="valback8">
          <div class="valinfo8">
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon15.png" />
              <div class="tal">搜索网吧</div>
              <div class="tel">
                <div></div>
                <div>实时定位，可按喜好、距离、星级等搜索网吧</div>
              </div>
              <div class="tel">
                <div></div>
                <div>货比三家，不走冤枉路，不花冤枉钱</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon16.png" />
            </div>
            <img src="../../assets/img/gonetwork2_8.png" />
          </div>
        </div>
        <div class="valback9">
          <div class="valinfo9">
            <img src="../../assets/img/gonetwork2_9.png" />
            <div class="valtext">
              <img class="img1" src="../../assets/img/gonetwork2_icon17.png" />
              <div class="tal">海量红包</div>
              <div class="tel">
                <div></div>
                <div>
                  网费红包可分享至微信、微博、QQ等社交平台，进行有力宣传
                </div>
              </div>
              <div class="tel">
                <div></div>
                <div>红包限时使用，精准营销，有效吸引用户</div>
              </div>
              <img class="img2" src="../../assets/img/gonetwork2_icon18.png" />
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/gonetworkfooter.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "gonetwork2",
  data() {
    return {};
  },
  methods: {
    //跳转首页
    gonetwork0() {
      this.$router.push("/gonetwork0");
    },
    //跳转产品介绍
    gonetwork1() {
      this.$router.push("/gonetwork1");
    },
    //跳转功能介绍
    gonetwork2() {
      this.$router.push("/gonetwork2");
    },
    //跳转下载
    gonetwork3() {
      this.$router.push("/gonetwork3");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 16px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 140px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #666666;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #ff6c00;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #ff6c00;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  .gonetworkmain {
    margin: 0 auto;
    height: 300px;
    background-image: url(../../assets/img/gonetwork2back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .valback1 {
    background: #ffffff;
    .valinfo1 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 599px;
        height: 752px;
      }
      .valtext {
        margin: 150px 0 0 159px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 546px;
          height: 156px;
          margin: 0 0 0 -14px;
        }
      }
    }
  }
  .valback2 {
    background: #f4f5f9;
    .valinfo2 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        width: 506px;
        margin: 122px 0 0 60px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 486px;
          height: 145px;
          margin: 30px 0 0 20px;
        }
      }
      > img {
        width: 602px;
        height: 750px;
        margin-left: 152px;
      }
    }
  }
  .valback3 {
    background: #ffffff;
    .valinfo3 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 602px;
        height: 752px;
      }
      .valtext {
        margin: 213px 0 0 156px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
    }
  }
  .valback4 {
    background: #f4f5f9;
    .valinfo4 {
      width: 1320px;
      margin: 0 auto;
      padding: 66px 0;
      display: flex;
      .valtext {
        margin: 106px 0 0 60px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 459px;
          height: 171px;
          margin: 30px 0 0 22px;
        }
      }
      > img {
        width: 558px;
        height: 740px;
        margin-left: 140px;
      }
    }
  }
  .valback5 {
    background: #ffffff;
    .valinfo5 {
      width: 1320px;
      margin: 0 auto;
      padding: 66px 0;
      display: flex;
      > img {
        width: 416px;
        height: 740px;
        margin-left: 80px;
      }
      .valtext {
        margin: 164px 0 0 262px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 540px;
          height: 257px;
          margin: 0 0 0 -8px;
        }
      }
    }
  }
  .valback6 {
    background: #f4f5f9;
    .valinfo6 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        margin: 107px 0 0 60px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 460.5px;
          height: 207px;
          margin: 30px 0 0;
        }
      }
      > img {
        width: 590px;
        height: 752px;
        margin-left: 138px;
      }
    }
  }
  .valback7 {
    background: #ffffff;
    .valinfo7 {
      width: 1320px;
      margin: 0 auto;
      padding: 66px 0;
      display: flex;
      > img {
        width: 415.93px;
        height: 740px;
        margin-left: 80px;
      }
      .valtext {
        margin: 60px 0 0 266px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 484px;
          height: 262px;
          margin: 10px 0 0 -33px;
        }
        .tel2 {
          width: 100px;
          height: 27px;
          font-size: 18px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #8c97a8;
        }
        .img3 {
          width: 173px;
          height: 68px;
          margin-top: 9px;
        }
      }
    }
  }
  .valback8 {
    background: #f4f5f9;
    .valinfo8 {
      width: 1320px;
      margin: 0 auto;
      padding: 66px 0;
      display: flex;
      .valtext {
        margin: 49px 0 0 60px;
        .img1 {
          width: 448px;
          height: 60px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 451px;
          height: 391px;
          margin: 0;
        }
      }
      > img {
        width: 416px;
        height: 740px;
        margin-left: 202px;
      }
    }
  }
  .valback9 {
    background: #ffffff;
    .valinfo9 {
      width: 1320px;
      margin: 0 auto;
      padding: 66px 0;
      display: flex;
      > img {
        width: 416px;
        height: 740px;
        margin-left: 80px;
      }
      .valtext {
        margin: 79px 0 0 262px;
        .img1 {
          width: 100px;
          height: 100px;
        }
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
          margin: 20px 0 30px;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 20px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(318deg, #ff8000 0%, #ff9100 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        .img2 {
          width: 430px;
          height: 222px;
          margin: 20px 0 0 -13px;
        }
      }
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 140px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
